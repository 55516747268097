import { BoltIcon } from "@heroicons/vue/20/solid";

export const datapointEasyButton = {
  order: 1,
  name: "Understand the Easy Button",
  keywords: "upload split revenue datapoint",
  subcategory: "The Easy Button",
  icon: BoltIcon,
  iconColor: "text-amber-500",
  markdown: `# The Easy Button

  {% tablessContainer paddingStyles="px-5" %}
  **Who can use this feature?**

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="verify-your-email-address" %}**Verified users**{% /inlineRouterLink %}.
  {% /tablessContainer %}

  Nearly everyone who interacts with commercial real estate holds some valuable data. Tower Hunt, via {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoints{% /inlineRouterLink %}, maximizes the value and usefulness of your data. While we've gone to great lengths to provide an intuitive, well-documented set of tools for {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoint{% /inlineRouterLink %} creation, there is still a learning curve. {% inlineAppIcon iconName="easyDataInput" /%} The Easy Button is Tower Hunt's way to make data input effortless for you &mdash; while still delivering all of the rewards associated with building your own datapoints. Read on to find out how easy this option is and how it works.

  ## What to expect

  When you contribute data via the {% inlineAppIcon iconName="easyDataInput" /%} Easy Button, you are participating in a three-sided process with a number of possible outcomes. Here are some key characteristics of the system:

  #### Things that WILL happen

  - Tower Hunt team members will review and process your raw data.
  - Tower Hunt team members will know that your user account submitted the raw data.
  - You will recieve email status updates as the process unfolds.

  #### Things that WILL NOT happen

  - You will not be identified as the data contributor to other users.
  - You will not need to take any additional steps after contributing the raw data.
  - Files that you submit that Tower Hunt deems to be sensitive will not be distributed to other users.

  #### Things that MAY happen

  - Other users may be offered the opportunity to turn parts of your raw data into Tower Hunt {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoints{% /inlineRouterLink %}.
  - You may {% inlineRouterLink articleId="vote-on-validations" %}vote on validations{% /inlineRouterLink %} related to the datapoints that descend from your raw data.
  - You may earn a share of {% inlineRouterLink articleId="how-to-earn-on-tower-hunt" sectionName="Revenue types" %}cash and/or data credits{% /inlineRouterLink %} produced by datapoints that descend from your raw data.
  - Some or all of your raw data may not be eligible for entry into Tower Hunt.

  {% callout type="caution" %}
  **Important:** Make sure you have permission to share the data.
  {% /callout %}

  ## Add Data

  You will see the {% inlineAppIcon iconName="easyDataInput" /%} Easy Button at the top-center of Tower Hunt on desktop and mobile:

  ![Easy Button screenshot](https://assets.towerhunt.com/site/datapointEasyButton0.png)

  Contributions may include raw text (including links to web pages) and uploaded files. At least one of these two types of information is required. Then, press "Contribute". That's it!

  ## How it Works

  Clicking "Contribute" is actually just the first step toward making that information part of Tower Hunt. Read on to learn what happens next.

  ### The Players

  The Easy Button triggers a three-sided process involving:

  #### The Contributor / Referrer

  The {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="verify-your-email-address" %}verified user{% /inlineRouterLink %} who provides the raw data to Tower Hunt. In many cases, the Contributor is designated as a Referrer (eligible for a split of earnings) on some or all jobs.

  {% callout type="note" %}
  **Example of no Referrer:** The Contributor submits a lease comp for a building that isn't in Tower Hunt yet. In this case, there may be a data input job with no Referrer to create the property and building, followed by a job with a Referrer to create the space and lease data.
  {% /callout %}

  #### Tower Hunt

  Tower Hunt team members will review and process your raw data. Our goal is to translate your raw data into one or more data input jobs that can be completed by one or more Data Inputters.

  #### Data Inputters

  {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}Reputable users{% /inlineRouterLink %} who accept data input jobs.

  ### The Process

  Here are the steps after raw data is submitted via the Easy Button:

  1. Tower Hunt team members review the raw data.
  2. Pre-existing {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoints{% /inlineRouterLink %} are linked to the submission for context and so that data isn't duplicated.
  3. If no data needs to be added to Tower Hunt, the submission may be closed at this point. The contributor is notified.
  3. Data that needs to be added is packaged into one or more small jobs. We refer to this step as processing the raw data. The Contributor may be designated as a Referrer (eligible for a split of earnings) on some or all jobs.
  4. Jobs are distributed to {% inlineAppIcon iconName="user" /%}{% inlineRouterLink articleId="understand-reputation" sectionName="What reputation affects" %}reputable users{% /inlineRouterLink %} who we think can help faithfully and accurately input the data.
  5. Data Inputters accept jobs that they want to work on. A Data Inputter may work on one job at a time. Once accepted, a job expires in 24 hours. If a job expires, it may be redistributed as in Step 4.
  6. Data Inputters review the job and {% inlineRouterLink articleId="create-a-datapoint" %}create{% /inlineRouterLink %}, {% inlineRouterLink articleId="understand-how-datapoints-become-public" sectionName="Publish" %}publish{% /inlineRouterLink %}, and/or declassify the requested {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoints{% /inlineRouterLink %}. Those datapoints are linked to the job. {% inlineRouterLink articledId="what-is-safezone?" %}Safezone{% /inlineRouterLink %} datapoints are automatically shared with the Contributor on a double-blind basis.
  7. The Data Inputter marks the job complete. The Data Inputter may now accept another job.
  8. Once the job is marked complete, the job is considered finalized after all of the job's {% inlineRouterLink articleId="understand-how-datapoints-become-public" %}public datapoints are at rest{% /inlineRouterLink %}.
  9. Once a job is finalized, other dependent jobs may be distributed as in Step 4. When all jobs are finalized, the submission is finalized.

  Here is an example of a {% inlineRouterLink articleId="understanding-the-newsfeed" %}newsfeed card{% /inlineRouterLink %} that a Data Inputter might see when a job is distributed:

  ![Easy Button screenshot](https://assets.towerhunt.com/site/datapointEasyButtonJobOpportunityCard0.png)

  ## Referral Split

  The {% inlineAppIcon iconName="easyDataInput" /%} Easy Button offers the same types of rewards you would recieve for building your own datapoints &mdash; {% inlineRouterLink articleId="understand-pay-as-you-go-pricing" sectionName="How much does it cost" %}earnings{% /inlineRouterLink %} and {% inlineRouterLink articleId="understand-reputation" sectionName="What makes reputation change?" %}reputation{% /inlineRouterLink %} &mdash; only subject to a split between the {% inlineRouterLink articleId="the-easy-button" sectionName="The Players" %}Referrer{% /inlineRouterLink %} and the {% inlineRouterLink articleId="the-easy-button" sectionName="The Players" %}Data Inputter{% /inlineRouterLink %}. In practice, the Easy Button facilitates a JV between these two parties: the Referrer brings valuable data and the Data Inputter brings experience with the Tower Hunt data input tools. The referral split flexes based on two factors: **Datapoint Difficulty** and **Input Complexity**.

  {% callout type="caution" %}
  **Important:** Datapoints with no Referrer do not earn a split (ie, the Data Inputter keeps 100%).
  {% /callout %}

  {% callout type="tip" marginStyles="mt-4" %}
  **Important:** The percentages below are for the **Referrer's** split.
  {% /callout %}

  ### Datapoint Difficulty

  This grade considers how difficult it would be for an arm's length market participant to obtain the datapoint's information.

  | **High** | **Medium** | **Low** | **None** |
  |---|---|---|---|
  | 50% | 30% | 20% | 10% |

  #### Why it matters

  The Referrer earns a higher split for higher difficulty data. Even data that has "no" difficulty is eligible for a split because the Referrer proactively suggested it to Tower Hunt.

  ### Input Complexity

  This grade considers how difficult it is to structure and connect the data in Tower Hunt. The split is inversely correlated with the grade to reward the Data Inputter's expertise with Tower Hunt.

  | **High** | **Medium** | **Low** |
  |---|---|---|
  | 10% | 20% | 30% |

  #### Why it matters

  Structured, connected data &mdash; especially in Tower Hunt's format &mdash; is significantly more valuable than raw data. The Data Inputter earns a higher split for higher difficulty data.

  ### Effective Split

  The effective split is the sum of the Data Difficulty and Input Complexity splits. The effective split applies to each datapoint that descends from the raw data and that has a Referrer.

  The table below summarizes effective split (Referrer/Data Inputter) for each combination:

  | | **High Difficulty** | **Medium Difficulty** | **Low Difficulty** | **No Difficulty** |
  |---|---|---|---|---|
  | **High Complexity** | 60%/40% | 40%/60% | 30%/70% | 20%/80% |
  | **Medium Complexity** | 70%/30% | 50%/50% | 40%/60% | 30%/70% |
  | **Low Complexity** | 80%/20% | 60%/40% | 50%/50% | 40%/60% |
  `,
};
